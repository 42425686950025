<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/hardware-product" parent-url="硬件产品" current-url="网络产品" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n1.png" />
          <div class="about">
            <div class="title">网络产品</div>
            <div class="content">
              信息化的二三层的基础设施，包含无线网络、有线网络的整体网络解决方案，常用于办公区和IDC有线核心网络，市面的华三，华为的数通设备。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">新华三</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-15.png">
            <div class="company-info">
              新华三集团（H3C）作为数字化解决方案领导者，致力于成为客户业务创新、数字化转型值得信赖的合作伙伴。作为紫光集团旗下的核心企业，新华三通过深度布局“芯-云-网-边-端”全产业链，不断提升数字化和智能化赋能水平。新华三拥有芯片、计算、存储、网络、5G、安全、终端等全方位的数字化基础设施整体能力，提供云计算、大数据、人工智能、工业互联网、信息安全、智能联接、边缘计算等在内的一站式数字化解决方案，以及端到端的技术服务。同时，新华三也是HPE服务器、存储和技术服务的中国独家提供商。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">华为</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-16.png">
            <div class="company-info">
              华为技术有限公司，成立于1987年，总部位于广东省深圳市龙岗区。华为是全球领先的信息与通信技术（ICT）解决方案供应商，专注于ICT领域，坚持稳健经营、持续创新、开放合作，在电信运营商、企业、终端和云计算等领域构筑了端到端的解决方案优势，为运营商客户、企业客户和消费者提供有竞争力的ICT解决方案、产品和服务，并致力于实现未来信息社会、构建更美好的全联接世界。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">锐捷</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-17.png">
            <div class="company-info">
              锐捷网络是数据通信解决方案品牌。锐捷网络坚持走自主研发的道路，以“场景创新”在竞争激烈的网络设备市场走出独树一帜的发展大道。从成立之初，锐捷网络就确定了自己的使命“推动网络技术发展, 紧随网络应用浪潮，让技术与应用融合,促进社会进步。”锐捷网络成立于2000年1月，原名实达网络，2003年更名，自成立以来，一直扎根行业，深入场景进行解决方案设计和创新，并利用云计算、SDN、移动互联、大数据、物联网、AI等新技术为各行业用户提供场景化的数字解决方案，助力全行业数字化转型升级。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">Aruba</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-18.png">
            <div class="company-info">
              Aruba(安移通网络科技(中国)有限公司) 作为全球分布式企业网络的领导者，屡获殊荣的校园、分支机构/远程工作人员产品组合与移动解决方案使用户无论使用何种设备，身处何地或何种网络，都能简化运营并安全访问所有公司应用和服务，显著提高了效率并降低了资本和运营成本。            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
